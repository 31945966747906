/* eslint-disable no-undef */
//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import i18n from '../../i18n';
import { api, driverApi } from '../../utils/api';
import numeral from 'numeral'
import DistributionChart from './DistributionChart';
import $ from 'jquery'
import { useSelector } from 'react-redux';


const loadScript = function (src) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

// Hook
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const validateNumber = (value) => {
    console.log("validateNumber", value)
    if (value == -Infinity || isNaN(value)) {
        value = 0
    }
    return value
}



const Dashboard = () => {
    const [Dashboard, setDashboard] = useState(null)
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const auth = useSelector((state: any) => state.auth)
    const [Sales, setSales] = useState([])
    const [DriverTotals, setDriverTotals] = useState([])

    
    useEffect(() => {
        setDOMLoaded(true)

    }, [])

    const prevDOMLoaded = usePrevious(Dashboard)

    const getDashboard = () => {
        let businesses = ""

        auth.user.businesses.forEach((bussines) => {

            if (businesses) {
                businesses += ","
            }

            businesses += "'" + bussines.external_id + "'"
        })
        driverApi.get("/orders/dashboard?restaurants=" + businesses)
            .then(({ data }) => {
                console.log("dashboard", data)
                setDashboard(data)
            })
            .catch((e) => {
                console.warn("could not get dashboard", e)
            })
    }

    const getDriverTotal = () => {

        let businesses = ""

        auth.user.businesses.forEach((bussines) => {

            if (businesses) {
                businesses += ","
            }

            businesses += "'" + bussines.external_id + "'"
        })

        driverApi.get("/orders/top-drivers?restaurants=" + businesses)
            .then(({ data }) => {
                console.log("dashboard", data)
                setDriverTotals(data)
            })
            .catch((e) => {
                console.warn("could not get dashboard", e)
            })
    }
    const getSales = () => {

        let businesses = ""

        auth.user.businesses.forEach((bussines) => {

            if (businesses) {
                businesses += ","
            }

            businesses += "'" + bussines.external_id + "'"
        })

        driverApi.get("/orders/sales?restaurants=" + businesses)
            .then(({ data }) => {
                console.log("dashboard", data)
                setSales(data)
            })
            .catch((e) => {
                console.warn("could not get dashboard", e)
            })
    }

    useEffect(() => {
        console.log("prevDOMLoaded, DOMLoaded, Dashboard", prevDOMLoaded, DOMLoaded, Dashboard)
        if (!prevDOMLoaded && DOMLoaded && Dashboard) {
            //@ts-ignore
            loadScript('/js/dashboard.js')
            buildGraphs()

        }
    }, [prevDOMLoaded, DOMLoaded, Dashboard])

    useEffect(() => {

        if (auth.user) {
            getDashboard()
            getSales()
            getDriverTotal()
        }


    }, [auth.user])

    const getTextColor = (value) => {
        return value > 0 ? "text-success" : "text-danger"
    }

    const getArrowDirection = (value) => {
        return value > 0 ? " mdi-arrow-up" : " mdi-arrow-down"
    }



    let restaurantChange = 0
    let ordersChange = 0
    let userEntranceChange = 0
    let userTotal = 0

    if (Dashboard) {
        restaurantChange = validateNumber((Dashboard.res_today - (Dashboard.res_yesterday)) / Dashboard.res_today * 100)
        ordersChange = validateNumber((Dashboard.count_orders_today - (Dashboard.count_orders_yesterday)) / Dashboard.count_orders_today * 100)
        if (ordersChange)
            ordersChange = ordersChange.toFixed(2)
        if (restaurantChange)
            restaurantChange = restaurantChange.toFixed(2)
        userEntranceChange = validateNumber((Dashboard.user_entrance_today / (Dashboard.user_entrance_yesterday)))
        userTotal = validateNumber((Dashboard.users_today / (Dashboard.users_yesterday)))

    }

    const buildGraphs = () => {


        if ($("#sale-report-chart").length) {
            var CurrentChartCanvas = $("#sale-report-chart").get(0).getContext("2d");
            var CurrentChart = new Chart(CurrentChartCanvas, {
              type: 'bar',
              data: {
                labels: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת‎" ],
                datasets: [
                    {
                        label : "sales",
                        data: [Sales.day1,Sales.day2,Sales.day3,Sales.day4,Sales.day5,Sales.day6,Sales.day7 ],
                    backgroundColor: ["#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4","#e0f2ff","#3da5f4"]
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: true,
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                  }
                },
                scales: {
                  yAxes: [{
                    display: true,
                    gridLines: {
                      drawBorder: false
                    },
                    ticks: {
                      fontColor: "#000",
                      display: true,
                      padding: 20,
                      fontSize: 14,
                      stepSize: 10000,
                      callback: function(value) {
                        var ranges = [
                            { divider: 1e6, suffix: 'M' },
                            { divider: 1e3, suffix: 'k' }
                        ];
                        function formatNumber(n) {
                            for (var i = 0; i < ranges.length; i++) {
                              if (n >= ranges[i].divider) {
                                  return (n / ranges[i].divider).toString() + ranges[i].suffix;
                              }
                            }
                            return n.toFixed(2);
                        }
                        return "₪" + formatNumber(value);
                      }
                    }
                  }],
                  xAxes: [{
                    stacked: false,
                    categoryPercentage: .6,
                    ticks: {
                      beginAtZero: true,
                      fontColor: "#000",
                      display: true,
                      padding: 20,
                      fontSize: 14
                    },
                    gridLines: {
                      color: "rgba(0, 0, 0, 0)",
                      display: true
                    },
                    barPercentage: .7
                  }]
                },
                legend: {
                  display: false
                },
                elements: {
                  point: {
                    radius: 0
                  }
                }
              }
            });
          }

        // eslint-disable-next-line no-undef
        if ($("#total-sales-chart").length) {


            var areaData = {
                labels: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת‎" ],
                datasets: [
                    {
                        data: [Sale.day1,Sale.day2,Sale.day3,Sale.day4,Sale.day5,Sale.day6,Sale.day7 ],
                        backgroundColor: [
                            'rgba(61, 165, 244, .0)'
                        ],
                        borderColor: [
                            'rgb(61, 165, 244)'
                        ],
                        borderWidth: 2,
                        fill: 'origin',
                        label: "services"
                    }
                ]
            };
            
        }

        
    }




    return (



        <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
                {Dashboard ? <div className="content-wrapper">

                    <div className="row">
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-success">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-success icon-rounded-lg">
                                            <i className="mdi mdi-arrow-top-right"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{i18n.t("deliveries")}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">{numeral(Dashboard.orders_count).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-info">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-info icon-rounded-lg">
                                            <i className="mdi mdi-basket"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{i18n.t("deliveries_under_40")}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">{numeral(Dashboard.less_40_minutes).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-danger">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-danger icon-rounded-lg">
                                            <i className="mdi mdi-chart-donut-variant"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{i18n.t("deliveries_above_60")}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">{numeral(Dashboard.more_60_minutes).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-warning">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-warning icon-rounded-lg">
                                            <i className="mdi mdi-chart-multiline"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{i18n.t("active_drivers")}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">{numeral(Dashboard.active_drivers).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <DistributionChart driverTotal={DriverTotals}/>
                        <div className="col-xl-9 grid-margin stretch-card">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-10 col-lg-10 col-xl-10">
                                        <div className="card-body h-100 d-flex flex-column">
                                            <p className="card-title">{i18n.t("sale-report-overview")}</p>

                                            <canvas id="sale-report-chart"></canvas>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <footer className="footer">
                    <div className="w-100 clearfix">
                        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Powered by <strong><a href="http://www.menual.net">Menual</a></strong></span>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Dashboard


