import React, { useEffect, useState } from 'react'
import { api, driverApi } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import numeral from 'numeral'
import { TableExport } from 'tableexport';
const loadScript = function (src, id) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

const MyDrivers = () => {
    const [TableHeight, setTableHeight] = useState("50vh")
    const [SelectedBusiness, setSelectedBusiness] = useState("")

    const [Report, setReport] = useState([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    useEffect(() => {
        setDOMLoaded(true)

    }, [])

    useEffect(() => {

        if (DOMLoaded) {
            //@ts-ignore
            // loadScript('/js/dashboard.js', "dashboard")
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state) => state.auth)

    const getFinancialReport = () => {
        let businesses = ""

        auth.user.businesses.forEach((bussines) => {

            if (businesses) {
                businesses += ","
            }

            businesses += "'" + bussines.external_id + "'"
        })
        driverApi.get(`/orders/my-drivers?restaurants=${businesses}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}`)
            .then(({ data }) => {
                console.log("got report", data)
                setReport(data)
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

    // useEffect(() => {
    //     if (auth.user && auth.user.restaurants.length)
    //         //@ts-ignore
    //         setSelectedBusiness(auth.user?.restaurants.map((resta) => resta.id).join(","))
    // }, [auth])


    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
    }, [Report, DOMLoaded])

    useEffect(() => {
        if (auth.user)
            getFinancialReport()

    }, [auth.user, startDate, endDate])


    useEffect(() => {

        let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight

        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [Report])


    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="" style={{
            }}>
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card" style={{

                        }}>
                            <div className="card-body" style={{
                            }}>
                                <h4 className="card-title">{i18n.t("financial-data")}</h4>
                                <div className="card-description">
                                    {/* <div className="form-group">
                                        <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                                        <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                                            console.log("onSelected Business", e.target.value)
                                            auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                                                //@ts-ignore
                                                if(restaurant.id == e.target.value)
                                                    setSelectedBusiness(restaurant)
                                            })
                                            
                                        }}>
                                            {auth.user?.restaurants && auth.user?.restaurants.map((restaurant: RestaurantType) => {
                                                return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                                            })}
                                        </select>
                                    </div> */}
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row'
                                    }}>

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("start-date")}</span>
                                        <DatePicker
                                            onChange={(date) => {
                                                console.log("change startDate", date)
                                                onChangeStartDate(dayjs(date ? date : defaultStartDate))
                                            }
                                            }
                                            value={startDate.toDate()}
                                        />
                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("end-date")}</span>
                                        <DatePicker
                                            onChange={(date) => {
                                                console.log("onChange endDate", date)
                                                onChangeEndDate(dayjs(date ? date : defaultEndDate))
                                            }
                                            }
                                            value={endDate.toDate()}
                                        />

                                        <button className="btn btn-success" style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }} onClick={() => {

                                            /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                                            TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                                            //@ts-ignore
                                            new TableExport(document.getElementsByTagName("table"), {
                                                bootstrap: true,
                                                RTL: true,
                                                filename : "financial",
                                                sheetname : "financial"
                                            });
                                        }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>
                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX : 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{i18n.t("driver-name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("driver-id")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("phone")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("orders")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("less_40")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("more_60")}<i className="mdi mdi-chevron-down"></i></th>
                                                    
                                                    <th className="sortStyle">{i18n.t("tips")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{i18n.t("rate")}<i className="mdi mdi-chevron-down"></i></th>

                                                  


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Report.map((data: any, index: number) => {
                                                    return <tr key={index}>
                                                        <td>{data.name}</td>
                                                        <td>{data.id}</td>
                                                        
                                                        <td>{data.phone}</td>
                                                        <td>{data.orders_count}</td>
                                                        <td>{data.less_40_minutes}</td>
                                                        <td>{data.more_60_minutes}</td>

                                                        
                                                        
                                                        <td>{data.orders_tip}</td>
                                                        <td>{data.rate}</td>
                                                        

                                                    </tr>
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default MyDrivers
