/* eslint-disable no-undef */
//@ts-nocheck
import React, { useEffect } from 'react'
import i18n from '../../i18n';
import $ from 'jquery'

const DistributionChart = ({driverTotal}) => {
    useEffect(() => {
        
        if (driverTotal.length && $("#distribution-chart").length) {

            let colorMap = {}
            let backgroundColor = []
            let names = driverTotal.map((driver) => {
                var randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
                colorMap[driver.id] = randomColor
                backgroundColor.push(randomColor)
                return driver.name
            })
            let count = driverTotal.map((driver) => {
                return driver.orders_count != "0" ? driver.orders_count : "1"
            })

            console.log("driverTotal", names, count)

           
            
            var areaData = {
                labels: names,
                datasets: [{
                    data: count,
                    backgroundColor: [
                        "#3da5f4", "#f1536e", "#fda006"
                    ],
                    borderColor: "rgba(0,0,0,0)"
                }
                ]
            };
            var areaOptions = {
                responsive: true,
                maintainAspectRatio: true,
                segmentShowStroke: false,
                cutoutPercentage: 72,
                elements: {
                    arc: {
                        borderWidth: 4
                    }
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true
                },
                legendCallback: function (chart) {
                    var text = [];
                    text.push('<div class="distribution-chart">');

                    driverTotal.forEach((driver) => {
                        // var randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
                        // colorMap[driver.id] = randomColor
                        // backgroundColor.push(randomColor)
                        
                        text.push('<div class="item"><div class="legend-label" style="border: 3px solid ' + colorMap[driver.id] + '"></div>');
                        text.push(`<p>${driver.name}</p>`);
                        text.push('</div>');
                    })
                    
                  
                    text.push('</div>');
                    return text.join("");
                },
            }
            var distributionChartPlugins = {
                beforeDraw: function (chart) {
                    var width = chart.chart.width,
                        height = chart.chart.height,
                        ctx = chart.chart.ctx;

                    ctx.restore();
                    var fontSize = .96;
                    ctx.font = "600 " + fontSize + "em sans-serif";
                    ctx.textBaseline = "middle";
                    ctx.fillStyle = "#000";

                    var text = "",
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 2;

                    ctx.fillText(text, textX, textY);
                    ctx.save();
                }
            }
            var distributionChartCanvas = $("#distribution-chart").get(0).getContext("2d");
            var distributionChart = new Chart(distributionChartCanvas, {
                type: 'doughnut',
                data: areaData,
                options: areaOptions,
                plugins: distributionChartPlugins
            });
            document.getElementById('distribution-legend').innerHTML = distributionChart.generateLegend();
        }
        return () => {

        }
    }, [driverTotal])
    return (
        <div className="col-xl-3 grid-margin stretch-card">
            <div className="card">
                <div className="card-body pb-0">
                    <p className="card-title mb-xl-0">{i18n.t("top-deliveries")}</p>
                </div>
                <canvas id="distribution-chart"></canvas>
                <div className="card-body">
                    <div id="distribution-legend" className="distribution-chart-legend pt-4 pb-3"></div>
                </div>
            </div>
        </div>
    )
}

export default DistributionChart
