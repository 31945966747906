
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import store from './configureStore'

import { Provider } from 'react-redux'

import dayjs from 'dayjs';
import i18n from "./i18n";

import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Logout from './pages/Logout';
import Main from './pages/Main';
import ContextProvider from "./ContextProvider";

require('dayjs/locale/he')
//@ts-ignore
dayjs.locale(i18n.getLang())

String.prototype.format = function () {
  let a = this;
  for (let k in arguments) {
    a = a.replace("{" + k + "}", arguments[k])
  }
  return a
}

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot" component={Forgot} />
              <Route exact path="/logout" component={Logout} />
              <Route path="/" component={Main} />

            </Switch>
          </Router>
        </Provider>
      </ContextProvider>
    </div>
  );
}

export default App;
