import React from 'react'
import { OrderProvider } from './OrderProvider'

const ContextProvider = ({children}) => {
  return (
    <OrderProvider>
      {children}
    </OrderProvider>
  )
}

export default ContextProvider