import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import i18n from '../../i18n'
import { setUserData } from '../../redux/authSlice'
import { clearJwtToken } from '../../utils/api'
import { MabaliColors } from '../../utils/Colors'

const Topbar = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();
    const auth = useSelector(state => state.auth)


    const doLogout = () => {
        dispatch(setUserData({}));
        clearJwtToken()
        history.replace("/login")

    }

    if (!auth.user) {
        return null
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark" style={{
            backgroundColor : MabaliColors.pink
        }}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src="/images/appstore.png" alt="mabali" style={{
                        height : 20,
                        borderRadius: 4,
                        marginLeft: 5,
                        marginRight: 5,
                    }} />
                    ניהול נהגים</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {<li className="nav-item" onClick={() => history.push("/financial")}>
                            <span className={"nav-link" + (location.pathname === "/financial" ? " active" : "")}>{i18n.t("business-financial")}</span>
                        </li>}
                       
                        <li className="nav-item" onClick={() => history.push("/my-drivers-data")}>
                            <span className={"nav-link" + (location.pathname === "/my-drivers-data" ? " active" : "")}>{i18n.t("drivers-data")}</span>
                        </li>
                        <li className="nav-item" onClick={() => history.push("/orders")}>
                            <span className={"nav-link" + (location.pathname === "/orders" ? " active" : "")}>{i18n.t("orders")}</span>
                        </li>
                        <li className="nav-item" onClick={() => history.push("/drivers-reviews")}>
                            <span className={"nav-link" + (location.pathname === "/drivers-reviews" ? " active" : "")}>{i18n.t("drivers-reviews")}</span>
                        </li>
                        {auth.user.driverManagerAdmin && <li className="nav-item" onClick={() => history.push("/managers")}>
                            <span className={"nav-link" + (location.pathname === "/managers" ? " active" : "")} aria-current="page" href="#">{i18n.t("managers")}</span>
                        </li>}
                        <li className="nav-item" onClick={() => history.push("/drivers")}>
                            <span className={"nav-link" + (location.pathname === "/drivers" ? " active" : "")} aria-current="page" href="#">{i18n.t("drivers")}</span>
                        </li>
                        {auth.user.driverManager && <li className="nav-item" onClick={() => history.push("/live-orders")}>
                            <span className={"nav-link" + (location.pathname === "/live-orders" ? " active" : "")} aria-current="page" href="#">{i18n.t("live-orders")}</span>
                        </li>}


                    </ul>
                    <div className="d-flex">
                        <button className="btn btn-danger" onClick={doLogout} >{i18n.t("logout")}</button>
                    </div>
                </div>
                <style>
                    {`
                        .nav-link {
                            cursor : pointer;
                        }

                        .nav-link:hover {
                            font-weight : 500;
                        }
                        .active {
                            font-weight : 700;
                        }
                    `}
                </style>
            </div>
        </nav>
    )
}

export default Topbar
