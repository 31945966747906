import React, { useEffect, useState } from 'react'
import { driverApi } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { TableExport } from 'tableexport';
import numeral from 'numeral';
const loadScript = function (src, id) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}


const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

const Reviews = ({ business, driver }) => {
    const [TableHeight, setTableHeight] = useState("50vh")
    const [SelectedBusiness, setSelectedBusiness] = useState("0")

    const [Report, setReport] = useState([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const match = useRouteMatch()
    useEffect(() => {
        setDOMLoaded(true)

    }, [])

    useEffect(() => {
        console.log("match", match)
        if (DOMLoaded) {
            //@ts-ignore
            loadScript('/js/dashboard.js', "dashboard")
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [match, DOMLoaded])


    const auth = useSelector((state) => state.auth)

    const getReviewsReport = () => {

        let businesses = ""

        auth.user?.businesses.forEach((bussines) => {

            if (businesses) {
                businesses += "&"
            }

            businesses += "business_in=" + bussines.id
        })

        driverApi.get(`/driver-reviews?${businesses}&created_at_gte=${startDate.format("YYYY-MM-DD")}&created_at_lte=${endDate.format("YYYY-MM-DD")}`)
            .then(({ data }) => {
                console.log("got report", data)
                setReport(data)
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

    useEffect(() => {
        if (auth.user && auth.user.businesses.length){
            setSelectedBusiness(auth.user?.businesses.map((resta) => resta.id).join(","))
        }
            //@ts-ignore
            // setSelectedBusiness(auth.user?.restaurants[0].id)
    }, [auth])


    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
    }, [Report, DOMLoaded])

    useEffect(() => {
        if (SelectedBusiness)
            getReviewsReport()

    }, [SelectedBusiness, startDate, endDate])


    useEffect(() => {

        let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight

        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [])

    let cardTitle = i18n.t("bolepo-reviews")
    let fileName = "bolepo-review"
    let sheetName = "bolepo-review"
    let columnRate = i18n.t("system-rate")
    let columContent = i18n.t("system-content")



    if (business) {
        cardTitle = i18n.t("business-reviews")
        fileName = "business-review"
        sheetName = "business-review"
        columnRate = i18n.t("review")
        columContent = i18n.t("content")
    }

    if (driver) {
        cardTitle = i18n.t("drivers-reviews")
        fileName = "driver-review"
        sheetName = "driver-review"
        columnRate = i18n.t("driver-rate")
        columContent = i18n.t("driver-content")
    }



    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="">
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{cardTitle}</h4>
                                <div className="card-description">
                                    <div className="form-group">
                                        <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                                        <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                                            console.log("onSelected Business", e.target.value)

                                            if(e.target.value == "0"){
                                                let selectedIds = ""
                                                auth.user?.businesses.forEach((restaurant) => {
                                                    if(selectedIds){
                                                        selectedIds += ","
                                                    }
                                                    selectedIds += restaurant.id
                                                })
                                                setSelectedBusiness(selectedIds)
                                            }else{
                                                auth.user?.businesses.forEach((restaurant) => {
                                                    //@ts-ignore
                                                    if (restaurant.id == e.target.value)
                                                        setSelectedBusiness(restaurant.id)
                                                })
    
                                            }
                                           
                                        }}>
                                            <option value={0} >{i18n.t("all")}</option>
                                            {auth.user?.businesses && auth.user?.businesses.map((restaurant) => {
                                                return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row'
                                    }}>

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("start-date")}</span>
                                        <DatePicker
                                            onChange={(date) => {
                                                console.log("change startDate", date)
                                                onChangeStartDate(dayjs(date ? date : defaultStartDate))
                                            }
                                            }
                                            value={startDate.toDate()}
                                        />
                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("end-date")}</span>
                                        <DatePicker
                                            onChange={(date) => {
                                                console.log("onChange endDate", date)
                                                onChangeEndDate(dayjs(date ? date : defaultEndDate))
                                            }
                                            }
                                            value={endDate.toDate()}
                                        />
                                        <button className="btn btn-success" style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }} onClick={() => {

                                            /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                                            TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                                            //@ts-ignore
                                            new TableExport(document.getElementsByTagName("table"), {
                                                bootstrap: true,
                                                RTL: true,
                                                filename : fileName,
                                                sheetname: sheetName
                                            });
                                        }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>
                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX: 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{i18n.t("business-name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("customer-name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("customer-telephone")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("customer-address")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{i18n.t("order-id")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("date-time")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("delivery-time")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{i18n.t("ordersum")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("businessFee")}</th>
                                                  
                                                    <th className="sortStyle">{columnRate}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{columContent}<i className="mdi mdi-chevron-down"></i></th>




                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Report.map((data: any, index: number) => {

                                                    let evaluation = data.rate
                                                    let feedback = data.content


                                                    if(data.rate == 0){
                                                        return null
                                                    }

                                                    let createdAt = dayjs(data.order.created_at)
                                                    let deliveryTime = dayjs(data.order.deliveryTime)
                                                    let timeToDeliver = deliveryTime.diff(createdAt, 'minute')


                                                    return <tr key={index}>
                                                        <td>{data.business.name}</td>
                                                        <td>{data.order.customer_name}</td>
                                                        <td>{data.order.customer_phone}</td>
                                                        <td>{(data.order.address + " #" + data.order.street_number + ", " + data.order.city)}</td>

                                                        <td><strong>{"(" + data.order.id + ")"}</strong> { data.order.external_id}</td>
                                                        <td>{dayjs(data.order.created_at).format("DD/MM/YY HH:mm")}</td>
                                                        <td>{(timeToDeliver) ? timeToDeliver : i18n.t("order-pending")}</td>

                                                        <td>₪{numeral(data.order.cost).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.order.businessFee).format("0,0.00")}</td>
                                                        <td>{evaluation}</td>
                                                        <td>{feedback}</td>

                                                    </tr>
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default Reviews
