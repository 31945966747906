import dayjs from 'dayjs'
import React from 'react'
import { Color } from '../../../utils/Colors'
import relativeTime from 'dayjs/plugin/relativeTime'
import i18n from '../../../i18n'
import numeral from 'numeral'
require('dayjs/locale/he')
dayjs.locale('he')
dayjs.extend(relativeTime)

const getStatus = (order) => {
  let badgeColor = 'green'
  let badgeText = i18n.t('order-started')

  if (order.picked) {
    badgeColor = 'orange'
    badgeText = i18n.t('order-picked')
  }

  if (order.orderReady) {
    badgeColor = 'purple'
    badgeText = i18n.t('order-ready')
  }
  return <span className={`badge rounded-pill`} style={{
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: badgeColor
  }}>{badgeText}</span>
}

const OrderCard = ({ order }) => {
  return (
    <div style={{

      width: "100%",
      flex: 1,
      height: 350,
      display: 'flex',
      marginBottom: 10,
      backgroundColor: Color.lightGray,
      flexDirection: 'column',
      "WebkitBoxShadow": "2px 2px 4px 0px rgba(0,0,0,0.43)",
      "boxShadow": "2px 2px 4px 0px rgba(0,0,0,0.43)",
    }}>
      <div style={{
        width: "100%",
        backgroundColor: order.order_color,
        display: 'flex',
        // height : 24,
        padding: 2,
        paddingLeft: 5,
        paddingRight: 5,
      }}>
        <span style={{
          flex: 1,
        }}>
          ({order.id}) - {order.restaurant_name}
        </span>
        {getStatus(order)}
        <span style={{

        }}>
          {order.external_id}
        </span>
      </div>
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 5,

      }}>
        <span style={{
          fontWeight: 700
        }}>
          [{order.driver?.name || 'מחפש נהג'}]
        </span>
        <span style={{
          fontSize: 14
        }}>
          {i18n.t("delivery-time-order").format(dayjs(order.deliveryTime).format("DD/MM/YY HH:mm:ss"))}
        </span>
        <span style={{

        }}>
          {order.name}
        </span>

        <span>
          {order.address}
        </span>
        <span style={{
          fontWeight: 800
        }}>
          {' ' + numeral(order.housedistance).format("0,0") + ' '}ק׳׳מ
        </span>
        <span>
          {order.customer_phone}
        </span>
        {order.driverAccepted ? <span style={{
          backgroundColor: '#333',
          color: "#fff"
        }}>
          {dayjs(order.driverAccepted).fromNow()}
        </span> : null}
        <span style={{
          fontSize: 14
        }}>
          {i18n.t("updated-time-order").format(dayjs(order.updated_at).format("DD/MM/YY HH:mm:ss"))}
        </span>

      </div>

    </div>
  )
}

export default OrderCard