import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Topbar from './components/Topbar'
import { useHistory, useLocation } from "react-router-dom";
import { getMe } from '../redux/authSlice';
import {
    Route
} from "react-router-dom";
import Managers from './Managers';
import Drivers from './Drivers';
import DriverDetails from './Drivers';
import Dashboard from './Dashboard'
import Financial from './Financial'
import MyDrivers from './MyDrivers'
import Orders from './Orders'
import Reviews from './Reviews/Reviews';
import LiveOrders from './LiveOrders';

const Main = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const auth = useSelector((state) => state.auth)
    const location = useLocation();

    useEffect(() => {
        if (auth.jwt) {
            dispatch(getMe())

        }
    }, [dispatch, auth.jwt])

    useEffect(() => {
        if (!auth.jwt || auth.code === 403) {
            history.push("/login")
        }

    }, [history, auth.jwt, auth.code])

    // useEffect(() => {

    //     if (auth.user && location.pathname === "/") {
    //         if (auth.user.driverManagerAdmin)
    //             history.push("/managers")
    //         else
    //             history.push("/drivers")
    //     }

    // }, [location.pathname, history, auth.user])

    return (
        <div>
            <Topbar />
            
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/managers" component={Managers} />
            <Route exact path="/drivers" component={Drivers} />
            <Route exact path="/drivers-reviews" component={(props) => <Reviews {...props} driver/>} />
            <Route exact path="/drivers/:id" component={DriverDetails} />
            <Route exact path="/financial" component={Financial} />
            <Route exact path="/my-drivers-data" component={MyDrivers} />
            <Route exact path="/live-orders" component={LiveOrders} />

            <Route path="/orders" component={Orders} />
        </div>
    )
}

export default Main
