import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import i18n from '../../i18n'
import { driverApi } from '../../utils/api'
import dayjs from 'dayjs'
import {
    GoogleMap,
    Marker,
    useJsApiLoader
} from '@react-google-maps/api'

const mapContainerStyle = {
    width: '100%',
    height: '100%'
}
const options = {
    disableDefaultUI: true,
    zoomControl: true
}

let addDriverModal = null
let driverLocationModal = null
let getDriverTimeout = null

const Drivers = () => {

    const auth = useSelector(state => state.auth)
    const [Username, setUsername] = useState("")
    const [Password, setPassword] = useState("")
    const [Phone, setPhone] = useState("")
    const [SelectedBusineses, setSelectedBusineses] = useState([])
    const [Businesses, setBusinesses] = useState([])
    const [Email, setEmail] = useState("")
    const [Name, setName] = useState("")
    const [EditUser, setEditUser] = useState(false)
    const history = useHistory()
    const [AddingDriver, setAddingDriver] = useState(false)
    const [DriverManagerAdmin, setDriverManagerAdmin] = useState(false)
    const [Drivers, setDrivers] = useState([])
    const [GetAllOrders, setGetAllOrders] = useState(false)
    const [DriverLocation, setDriverLocation] = useState(null)
    const [Center, setCenter] = useState({
        lat: 32.085543403635036,
        lng: 34.780881135951304
    })

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })


    const addDriver = async () => {
        console.log("addDriver", EditUser)
        setAddingDriver(true)
        if (EditUser) {
            let params = {

                phone: Phone,
                name: Name,
                businesses: SelectedBusineses,
                allOrders: GetAllOrders
            }

            try {

                if (Password) {
                    await driverApi.put("/users/driver/" + EditUser, { password: Password })
                }

                await driverApi.put("/drivers/" + EditUser, params)
                closeModal()
                getDrivers();
            }
            catch (e) {
                console.error('Could update user', e);

            }
            setAddingDriver(false)

        } else {

            let result = await driverApi.post("/drivers", {
                name: Name,
                phone: Phone,
                businesses: SelectedBusineses,
                confirmed: true,
                email: Email,
                driverManager: false,
                driverManagerAdmin: false,
                allOrders: GetAllOrders

            })

            let driver = result.data

            await driverApi.post("/auth/local/register", {
                username: Username,
                email: Email,
                password: Password,
                confirmed: true,
                driver: driver.id,
                driverManager: false,
                driverManagerAdmin: false,

            });

            setAddingDriver(false)
            console.log("myModal", addDriverModal)
            closeModal()
            getDrivers();
        }

        // .catch((e) => {
        //     alert(i18n.t("failed-creating-driver"))
        //     console.warn("could not add driver", e);
        // })
    }
    const getDrivers = () => {

        let driverBusinesses = []
        let filterParams = ""
        auth.user.businesses.forEach((bussiness) => {
            driverBusinesses.push(bussiness.id)
            if (filterParams) {
                filterParams += "&"
            }
            filterParams += "businesses_in=" + bussiness.id
        })

        let businessFilter = auth.user.driverManagerAdmin ? "" : "?" + filterParams
        driverApi.get("/drivers" + businessFilter)
            .then(({ data }) => {
                setDrivers(data)
            })
            .catch((e) => {
                console.warn("/drivers warn", e)
            })
    }
    const getBusinesses = () => {
        if (auth.user.driverManagerAdmin)
            driverApi.get("/businesses")
                .then(({ data }) => {
                    setBusinesses(data)
                })
                .catch((e) => {
                    console.warn("/businesses warn", e)
                })
        else {
            let userBusinesses = []
            auth.user.businesses.forEach((bussiness) => {
                userBusinesses.push(bussiness)
            })
            setBusinesses(userBusinesses);
        }

    }


    const onSelectedBusinesses = (e) => {
        // console.log("onBusinesses", e.target.selectedOptions)
        let businesses = []
        Array.from(e.target.selectedOptions).forEach((option) => {
            if (option.selected)
                businesses.push(option.value)
        })
        setSelectedBusineses(businesses)
    }

    const onDelete = () => {
        if (window.confirm(i18n.t("confirm-delete"))) {
            driverApi.delete("/drivers/" + EditUser)
                .then(({ data }) => {
                    closeModal()
                    getDrivers();
                })
                .catch((e) => {
                    console.warn("could not delete", e)
                })
        }
    }

    const closeLocationModal = () => {
        setDriverLocation(null)
        driverLocationModal.hide()

    }

    const closeModal = () => {
        console.log('closeModal')
        setPassword("")
        setEmail("")
        setPhone("")
        setName("")
        setUsername("")
        setGetAllOrders(false)
        setSelectedBusineses([])
        setEditUser(false)
        setAddingDriver(false)
        addDriverModal.hide()
    }

    useEffect(() => {
        if (auth.user && !auth.user.driverManager) {

            return history.replace("/")
        }

    }, [auth.user, history])

    useEffect(() => {

        if (GetAllOrders) {
            let selectAllBusiness = []
            auth.user.businesses.forEach((buss) => {
                selectAllBusiness.push(buss.id)
            })
            setSelectedBusineses(selectAllBusiness)
        }
    }, [GetAllOrders])



    useEffect(() => {
        addDriverModal = new window.document.bootstrap.Modal(document.getElementById('addDriver'))
        driverLocationModal = new window.document.bootstrap.Modal(document.getElementById('driverLocation'))

        if (auth.user && auth.user.businesses.length) {
            getDrivers()
            getBusinesses()
            if (getDriverTimeout) {
                clearTimeout(getDriverTimeout)
            }
            // getDriverTimeout = setTimeout(() => fetchData(), 10000)
        }


        return () => {
            if (getDriverTimeout) {
                clearTimeout(getDriverTimeout)
            }
        }
    }, [auth.user])

    useEffect(() => {

        if (EditUser) {


            addDriverModal.show()
        }

    }, [EditUser])
    useEffect(() => {

        if (DriverLocation) {

            console.log("driverLocationModal", driverLocationModal)
            driverLocationModal.show()
        }

    }, [DriverLocation])



    return (
        <div className="container" style={{

        }}>

            <h4>{i18n.t("drivers")}</h4>

            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{i18n.t("name")}</th>
                        <th scope="col">{i18n.t("email")}</th>
                        <th scope="col">{i18n.t("phone")}</th>
                        <th scope="col">{i18n.t("lastOnline")}</th>
                        <th scope="col">{""}</th>
                    </tr>
                </thead>
                <tbody>
                    {Drivers.map((driver, key) => {

                        let myself = (auth.user && driver.id === auth.user.id)

                        return <tr className={(myself ? " disabled2" : "highlight")} style={{
                            cursor: "pointer",

                        }} onClick={(e) => {

                            // if (myself) {
                            //     return
                            // }
                            if (driver.user)
                                setUsername(driver.user.username)
                            setEmail(driver.email)
                            setPhone(driver.phone)
                            setName(driver.name)
                            setGetAllOrders(driver.allOrders)
                            setPassword("")
                            let businesses = []
                            driver.businesses.forEach((business) => {
                                businesses.push(business.id)
                            })
                            setSelectedBusineses(businesses)
                            console.log("driver", driver.id)
                            setEditUser(driver.id)
                        }} key={key}>
                            <th scope="row">{key + 1}</th>
                            <td>{driver.name}</td>
                            <td>{driver.email}</td>
                            <td>{driver.phone}</td>
                            <td>
                                <div style={{
                                    display: 'flex',
                                    gap: 10
                                }}>
                                    <div style={{
                                        backgroundColor: driver.online ? 'green' : 'red',
                                        height: 20,
                                        width: 20,
                                        borderRadius: '100%'
                                    }} />
                                    {dayjs(driver.lastonline).format("DD/MM/YYYY HH:mm")}
                                </div>
                            </td>
                            <td>{driver.currentlocation && <button onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                console.log("currentlocation", driver.currentlocation)
                                setDriverLocation(driver.currentlocation)
                                setCenter({
                                    lat: driver.currentlocation.latitude,
                                    lng: driver.currentlocation.longitude
                                })
                                driverLocationModal.show();
                            }} className="btn btn-success">{i18n.t("check-location")}</button>}</td>
                        </tr>
                    }
                    )}

                </tbody>
            </table>

            <div className="modal" id="addDriver" tabIndex={-1}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("drivers")}</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">{i18n.t("username")}</label>
                                <input disabled={EditUser} type="text" className="form-control" value={Username} onChange={(e) => setUsername(e.target.value)} id="exampleFormControlInput1" placeholder="name@example.com" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="emailInput" className="form-label">{i18n.t("email")}</label>
                                <input type="text" disabled={EditUser} className="form-control" value={Email}
                                    onChange={(e) => setEmail(e.target.value)} id="emailInput" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="nameInput" className="form-label">{i18n.t("name")}</label>
                                <input type="text" className="form-control" value={Name}
                                    onChange={(e) => setName(e.target.value)} id="nameInput" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phoneInput" className="form-label">{i18n.t("phone")}</label>
                                <input type="text" className="form-control" value={Phone}
                                    onChange={(e) => setPhone(e.target.value)} id="phoneInput" />
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" checked={GetAllOrders}
                                    onChange={(e) => setGetAllOrders(!GetAllOrders)} type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {i18n.t("get-all-orders")}
                                </label>
                            </div>
                            {!GetAllOrders && <select className="form-select" value={SelectedBusineses} multiple aria-label="multiple select example"
                                onChange={onSelectedBusinesses}>
                                <option>{i18n.t("select-businesses")}</option>
                                {Businesses.map((business, key) => {

                                    let selected = false;
                                    SelectedBusineses.forEach((inner) => {
                                        if (inner.id === business.id)
                                            selected = true
                                    })
                                    return <option key={key} value={business.id}>
                                        {business.name}{business.city ? " - " + business.city : null}
                                    </option>
                                })}
                            </select>}
                            <div className="mb-3">
                                <label htmlFor="passwordInput" className="form-label">{i18n.t("password")}</label>
                                <input type="password" className="form-control"
                                    onChange={(e) => setPassword(e.target.value)} id="passwordInput" />
                            </div>

                            {/* <div className="form-check">
                                <input className="form-check-input" checked={DriverManagerAdmin} onChange={(e) => setDriverManagerAdmin(!DriverManagerAdmin)} type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {i18n.t("admin")}
                                </label>
                            </div> */}
                        </div>


                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={onDelete} data-bs-dismiss="modal">{i18n.t("delete")}</button>
                            <button type="button" className="btn btn-secondary" onClick={closeModal} data-bs-dismiss="modal">{i18n.t("close")}</button>

                            {/* <button type="button" onClick={check} disabled={AddingDriver} className="btn btn-primary">{i18n.t(EditUser ? "save" : "add")}</button> */}
                            <button type="button" onClick={addDriver} disabled={AddingDriver} className="btn btn-primary">{i18n.t(EditUser ? "save" : "add")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal " id="driverLocation" tabIndex={-1}>
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("driver-location")}</h5>
                            <button type="button" className="btn-close" onClick={() => closeLocationModal()} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{
                            position: "relative"
                        }} >
                            <div style={{
                                height: "100%",
                                width: "100%",
                                position: "relative"
                            }}>
                                {DriverLocation && isLoaded && <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={15}

                                    center={Center}
                                    onLoad={map => {
                                        //mapRef.current = map
                                    }}
                                    options={options}
                                >

                                    {DriverLocation ? (
                                        <Marker
                                            position={{
                                                lat: DriverLocation.latitude,
                                                lng: DriverLocation.longitude
                                            }}
                                            onIconChanged={() => console.log('onIconChangd')}
                                            icon={{
                                                url: `/images/driver/driver-marker-${Math.abs(Math.floor(
                                                    DriverLocation.heading
                                                ))}.png`
                                            }}
                                        //icon="/images/driver-marker.png"
                                        />
                                    ) : null}

                                </GoogleMap>}
                            </div>
                        </div>


                        <div className="modal-footer">

                            <button type="button" className="btn btn-secondary" onClick={closeModal} data-bs-dismiss="modal">{i18n.t("close")}</button>

                        </div>
                    </div>
                </div>
            </div>
            <style>
                {`
                    .highlight:hover {
                        background-color: #333;
                        color: #fff;
                    }

                    .disabled2 {
                        background-color : #F1F1F1;
                    }

                `}
            </style>

            <button style={{
                position: 'absolute',
                bottom: 20,
                right: 20
            }} className="btn btn-primary" onClick={() => {
                console.log("myModal", addDriverModal)
                addDriverModal.show()

            }} >{i18n.t("add")}</button>

        </div>
    )
}

export default Drivers
