import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import i18n from '../../i18n'
import { api, driverApi } from '../../utils/api'

let addManagerModal = null

const Managers = () => {

    const auth = useSelector(state => state.auth)
    const [Username, setUsername] = useState("")
    const [Password, setPassword] = useState("")
    const [Email, setEmail] = useState("")
    const [EditUser, setEditUser] = useState(false)
    const history = useHistory()
    const [DriverManagerAdmin, setDriverManagerAdmin] = useState(false)
    const [Managers, setManagers] = useState([])
    const [Businesses, setBusinesses] = useState([])
    const [SelectedBusineses, setSelectedBusineses] = useState([])

    const addDriverManager = () => {
        console.log("addDriverManager", EditUser)
        if (EditUser) {
            let params = {

                driverManagerAdmin: DriverManagerAdmin,
                businesses: SelectedBusineses,
            }

            if (Password) {
                params.password = Password
            }

            driverApi.put("/users/" + EditUser, params)
                .then(({ data }) => {
                    console.log("myModal", addManagerModal)
                    addManagerModal.toggle()
                    setEditUser(false)
                    getManagers();
                })
        } else {
            driverApi.post("/auth/local/register", {
                username: Username,
                email: Email,
                password: Password,
                confirmed: true,
                driverManager: true,
                driverManagerAdmin: DriverManagerAdmin,
                businesses: SelectedBusineses,
            })
                .then(({ data }) => {
                    console.log("myModal", addManagerModal)
                    addManagerModal.toggle()
                    setEditUser(false)
                    getManagers();
                })
        }

        // .catch((e) => {
        //     alert(i18n.t("failed-creating-driver"))
        //     console.warn("could not add driver", e);
        // })
    }
    const getManagers = () => {

        driverApi.get("/users?driverManager=true")
            .then(({ data }) => {
                // console.log("getManagers", data)
                setManagers(data)
            })
            .catch((e) => {
                console.warn("users/drivers warn", e)
            })
    }

    const onDelete = () => {
        if(window.confirm(i18n.t("confirm-delete"))){
            driverApi.delete("/users/" + EditUser)
            .then(({data}) => {
                addManagerModal.toggle()
                setEditUser(false)
                getManagers();
            })
            .catch((e) => {
                console.warn("could not delete", e)
            })
        }
    }

    useEffect(() => {
        if (auth.user && !auth.user.driverManagerAdmin) {

            return history.replace("/")
        }

    }, [auth.user, history])

    useEffect(() => {
        
        addManagerModal = new window.document.bootstrap.Modal(document.getElementById('addManager'))
        getManagers()
        getBusinesses()

    }, [])

    useEffect(() => {

        if (EditUser) {

            console.log("myModal", addManagerModal)
            addManagerModal.toggle()
        }

    }, [EditUser])

    const getBusinesses = () => {

        driverApi.get("/businesses")
            .then(({ data }) => {
                setBusinesses(data)
            })
            .catch((e) => {
                console.warn("/businesses warn", e)
            })
    }

    const onSelectedBusinesses = (e) => {
        // console.log("onBusinesses", e.target.selectedOptions)
        let businesses = []
        Array.from(e.target.selectedOptions).forEach((option) => {
            if (option.selected)
                businesses.push(option.value)
        })
        setSelectedBusineses(businesses)
    }


    const closeModal = () => {
        setPassword("")
        setEmail("")
        setUsername("")
        setSelectedBusineses([])
        setEditUser(false)
        addManagerModal.hide()
        setDriverManagerAdmin(false)
        setEditUser(false)
    }



    return (
        <div className="container" style={{

        }}>

            <h4>{i18n.t("driver-managers")}</h4>

            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{i18n.t("username")}</th>
                        <th scope="col">{i18n.t("email")}</th>
                        <th scope="col">{i18n.t("admin")}</th>
                    </tr>
                </thead>
                <tbody>
                    {Managers.map((manager, key) => {
                    
                    let myself = (auth.user && manager.id === auth.user.id)
                    
                    return <tr className={ (myself ? " disabled2" : "highlight")} style={{
                        cursor: !myself ? "pointer" : "inherit",

                    }} onClick={(e) => {

                        if(myself){
                            return
                        }
                        setEmail(manager.email)
                        setDriverManagerAdmin(manager.driverManagerAdmin)
                        setUsername(manager.username)
                        setPassword("")
                        console.log("manager", manager.id)
                        setEditUser(manager.id)

                        let businesses = []
                            manager.businesses.forEach((business) => {
                                businesses.push(business.id)
                            })
                            setSelectedBusineses(businesses)

                    }} key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>{manager.username}</td>
                        <td>{manager.email}</td>
                        <td>{manager.driverManagerAdmin ? i18n.t("yes") : ""}</td>
                    </tr>}
                    )}

                </tbody>
            </table>

            

            <div className="modal" id="addManager" tabIndex={-1}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("driver-managers")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">{i18n.t("username")}</label>
                                <input disabled={EditUser} type="text" className="form-control" value={Username} onChange={(e) => setUsername(e.target.value)} id="exampleFormControlInput1" placeholder="name@example.com" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="emailInput" className="form-label">{i18n.t("email")}</label>
                                <input type="text" disabled={EditUser} className="form-control" value={Email} onChange={(e) => setEmail(e.target.value)} id="emailInput" placeholder={i18n.t("email")} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="passwordInput" className="form-label">{i18n.t("password")}</label>
                                <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} id="passwordInput" placeholder={i18n.t("password")} />
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" checked={DriverManagerAdmin} onChange={(e) => setDriverManagerAdmin(!DriverManagerAdmin)} type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {i18n.t("admin")}
                                </label>
                            </div>

                            {auth.user && auth.user.driverManagerAdmin ? <select className="form-select" value={SelectedBusineses} multiple aria-label="multiple select example" onChange={onSelectedBusinesses}>
                                <option>{i18n.t("select-businesses")}</option>
                                {Businesses.map((business, key) => {

                                    let selected = false;
                                    SelectedBusineses.forEach((inner) => {
                                        if (inner.id === business.id)
                                            selected = true
                                    })
                                    return <option key={key} value={business.id}>
                                            {business.name}{business.city ? " - " + business.city : null}
                                            </option>
                                })}
                            </select> : null}
                        </div>


                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={onDelete} data-bs-dismiss="modal">{i18n.t("delete")}</button>
                            <button type="button" className="btn btn-secondary" onClick={closeModal} data-bs-dismiss="modal">{i18n.t("close")}</button>

                            <button type="button" onClick={addDriverManager} className="btn btn-primary">{i18n.t(EditUser ? "save" : "add")}</button>
                        </div>
                    </div>
                </div>


            </div>
            <style>
                {`
                    .highlight:hover {
                        background-color: #333;
                        color: #fff;
                    }

                    .disabled2 {
                        background-color : #F1F1F1;
                    }

                `}
            </style>

            <button style={{
                position: 'absolute',
                bottom: 20,
                right: 20
            }} className="btn btn-primary" onClick={() => {
                console.log("myModal", addManagerModal)
                addManagerModal.toggle()

            }} >{i18n.t("add")}</button>

        </div>
    )
}

export default Managers
