import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../configureStore'
import i18n from '../i18n'
import { clearLoginError, login } from '../redux/authSlice'
import { Color, MabaliColors } from '../utils/Colors'

const Login = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const auth = useSelector((state: RootState) => state.auth)


    useEffect(() => {
        console.log("auth.jwt", auth.jwt)
        if (auth.jwt) {
            history.replace("/managers")
        }

    }, [auth, history])

    const onChange = (value, name) => {
        switch (name) {
            case "Email":
                setEmail(value)
                break;
            case "Password":
                setPassword(value)
                break;
            default:
                break;
        }

    }

    useEffect(() => {

        if (auth.loginError) {

        }
    }, [auth.loginError])

    const doLogin = (e) => {
        e.preventDefault();
        dispatch(login({
            identifier: Email,
            password: Password
        }))
    }

    const clearError = () => {
        dispatch(clearLoginError())
    }

    return (
        <div className="container-fluid" style={{
            display: 'flex',
            flex: 1,
            height: "100vh",
            width: "100%",
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: MabaliColors.pink
        }}>

            <form className="shadow" style={{
                padding: 20,
                borderRadius: 10,
                zIndex: 999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#fff'
            }}>
                <img alt="bolepo-icon" src="/images/appstore.png" style={{
                    height: 50,
                    // width: 50,
                    display: 'flex',
                    alignSelf: 'center',
                    borderRadius: 10

                }} />

                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">{i18n.t('signup-email-label')}</label>
                    <input onChange={(e) => onChange(e.target.value, "Email")} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    
                </div>
                <div className="mb-3" style={{
                    width: "100%"
                }}>
                    <label htmlFor="exampleInputPassword1" placeholder={i18n.t('user-password-placeholder')} className="form-label">{i18n.t('user-password-placeholder')}</label>
                    <input onChange={(e) => onChange(e.target.value, "Password")} type="password" className="form-control" id="exampleInputPassword1" />
                </div>
                <button type="submit" onClick={doLogin} className="btn btn-primary">{i18n.t('login')}</button>
            </form>
            <a style={{
                color: "#fff"
            }} href="/forgot">{i18n.t("forgot-password")}</a>

            {auth.loginError !== "" && <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                   
                    <strong className="me-auto">{""}</strong>
                    {/* <small>now</small> */}
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={clearError}></button>
                </div>
                <div className="toast-body">
                    {auth.loginError}
                </div>
            </div>}

        </div>
    )
}

export default Login
