import numeral from "numeral"


const EstateMarker = ({ lat, lng, order }) => {

  return <div onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
  }} position={"relative"}
  style={{
    position : 'relative',
    width : 50,
    borderRadius : 4,
    justifyContent : 'center',
    display : 'flex',
    alignItems : 'center',
    height : 20,
    padding : 2,
    backgroundColor: order.order_color
  }}
    >
   

    <span style={{color:"#fff"}}>
      {order.id}
    </span>
  </div>
}

export default EstateMarker