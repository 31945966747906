import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import i18n from '../i18n'
import { api } from '../utils/api'

const Forgot = () => {
    const history = useHistory()
    const [Email, setEmail] = useState("")
    const auth = useSelector((state) => state.auth)


    useEffect(() => {
        console.log("auth.jwt", auth.jwt)
        if(auth.jwt){
            history.replace("/orders")
        }
        
    }, [auth, history])

    const onChange = (value, name) => {
        switch(name){
            case "Email":
                setEmail(value)
                break;
            default:
                break;
        }
        
    }

    const recoverPassword = (e) => {
        e.preventDefault()
        api.post("/admin/forgot-password", {
            email : Email,
            //url  : '/admin/auth/reset-password'
        })
        .then(({data}) => {
            window.location.href = "/login"
        })
        .catch((e) => {
            console.log("Could not reset", e)
        })
    }

    return (
        <div className="container-fluid" style={{
            display : 'flex',
            flex : 1,
            height : "100vh",
            width : "100%",
            flexDirection : 'column',
            justifyContent : 'center',
            alignItems : 'center',
            backgroundColor : "#7236C7"
        }}>

            <form className="shadow" style={{
                padding : 20,
                borderRadius : 10,
                zIndex : 999,
                display : 'flex',
                flexDirection : 'column',
                alignItems : 'center',
                backgroundColor : '#fff'
            }}>
                <img alt="bolepo-icon" src="/images/mabali-logo.png" style={{
                    height : 50,
                    // width : 50,
                    display : 'flex',
                    alignSelf : 'center',
                    
                }}/>

                <h1>{i18n.t("forgot-password")}</h1>

                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">{i18n.t("signup-email-label")}</label>
                    <input onChange={(e) => onChange(e.target.value, "Email")} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    <div id="emailHelp" className="form-text">דואר אלקטרוני ישלח אליך בדקות הקרובות עם קישור לאיפוס סיסמתך</div>
                </div>
               
                <button type="submit" onClick={recoverPassword} className="btn btn-primary">{i18n.t("send")}</button>
                </form>
            
        </div>
    )
}

export default Forgot
