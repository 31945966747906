import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { driverApi } from '../../utils/api';
import OrdersSideBar from './OrdersSideBar';
import OrdersMap from './OrdersMap';
import dayjs from 'dayjs';
import _ from 'lodash'
import { OrderContext } from '../../OrderProvider';
import { useHistory } from 'react-router-dom';

// var randomColor = Math.floor(Math.random()*16777215).toString(16);
let getOrdersTimeout = null
const OrderColorMap = {}
const LiveOrders = () => {

  const auth = useSelector((state) => state.auth)
  const [ActiveOrders, setActiveOrders] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (getOrdersTimeout) {
      clearInterval(getOrdersTimeout)
    }
    getOrdersTimeout = setInterval(() => {
      getOrders();
    }, 30000)
    getOrders()
    console.log('auth', auth)
    if (!auth.user?.driverManager) {
      history.replace("/")
    }
  }, []);

  const getOrders = () => {
    //const strapiFilters = `?created_at_gte=${dayjs().format("YYYY-MM-DD")}`
    //&completed=false&canceled=false&created_at_gte=${dayjs().format("YYYY-MM-DD")}
    const strapiFilters = `?completed=false&canceled=false&created_at_gte=${dayjs().format("YYYY-MM-DD")}`
    driverApi.get(`/orders${strapiFilters}`)
      .then(({ data }) => {

        data.forEach((order, key) => {
          if (OrderColorMap[key]) {
            data[key].order_color = OrderColorMap[key]
            return
          }


          data[key].order_color = "#" + Math.floor(Math.random() * 16777215).toString(16);
          OrderColorMap[key] = data[key].order_color
        })
        console.log("got active orders", data)
        setActiveOrders(data)
      })
      .catch((e) => {
        console.log("could not get active orders", e.response.data)
      })
  }

  return (
    <div className='container-fluid' style={{
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    }}>
      <OrdersSideBar orders={ActiveOrders} />
      <OrdersMap orders={ActiveOrders} />
    </div>
  )
}

export default LiveOrders