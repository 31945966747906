import React, { useState } from 'react'

const OrderContext = React.createContext()
const OrderProvider = ({children}) => {

  const [OrderColorsMap, setOrderColorsMap] = useState({});
  return (
    <OrderContext.Provider value={{
      setOrderColorsMap,
      OrderColorsMap
    }}>
      {children}
    </OrderContext.Provider>
  )
}

export {
  OrderContext,
  OrderProvider
}