import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../i18n';
import { clearJwtToken, driverApi, getJwtToken, setAuthorization, storeJwtToken } from '../utils/api';


export const login = createAsyncThunk(
    'auth/login',
    async (params) => {
        console.log("authSlice login baseURL", driverApi.defaults.baseURL + '/auth/local');
        console.log("authSlice login params", params);
        const response = await driverApi.post(
            '/auth/driver',
            params
        );
        console.log('login auth response', response.data);
        return response.data;
    }
);
export const getMe = createAsyncThunk(
    'auth/getMe',
    async () => {
        const response = await driverApi.get(
            '/driver-manager/me'
        );
        return response.data;
    }
);

export const createMember = createAsyncThunk(
    'auth/create',
    async (params) => {
        console.log("authSlice login baseURL", driverApi.defaults.baseURL + '/auth/local/register');
        console.log("authSlice login params", params);
        const response = await driverApi.post(
            '/auth/local/register',
            params
        );
        console.log('create auth response', response.data);
        return response.data;
    }
);


const jwtToken = getJwtToken();

if(jwtToken){
    setAuthorization(jwtToken)
}

const initialState = {
    jwt: jwtToken,
    authenticating: false,
    code : 200,
    signUp: {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Birthday: "",
        Gender: "",
        Password: ""
    },
    creating: false,
    user: null,
    loginError: ""
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSignUpFirstName: (state, action) => {
            state.signUp.FirstName = action.payload
        },
        setSignUpLastName: (state, action) => {
            state.signUp.LastName = action.payload
        },
        setSignUpEmail: (state, action) => {
            state.signUp.Email = action.payload
        },
        setSignUpPhone: (state, action) => {
            state.signUp.Phone = action.payload
        },
        setSignUpBirthday: (state, action) => {
            state.signUp.Birthday = action.payload
        },
        setSignUpGender: (state, action) => {
            state.signUp.Gender = action.payload
        },
        setSignUpPassword: (state, action) => {
            state.signUp.Password = action.payload
        },
        setUserData: (state, action) => {
            state.jwt = action.payload.jwt
            state.user = action.payload.user
        },
        clearLoginError: (state) => {
            state.loginError = ""
        }
    },
    extraReducers: (builder) => {
        //Fecth device info
        builder.addCase(login.pending, (state) => {
            state.authenticating = true;
            state.loginError = ""
        });
        builder.addCase(login.fulfilled, (state, action) => {
            storeJwtToken(action.payload.jwt)
            setAuthorization(action.payload.jwt)
            state.authenticating = false;
            state.jwt = action.payload.jwt
            state.user = action.payload.user
           

        });
        builder.addCase(login.rejected, (state, action) => {
            state.authenticating = false;
            console.log("invalid login", action.error.message)
            state.loginError = i18n.t("invalid-login")

        });
        //Get Me
        builder.addCase(getMe.pending, (state) => {
            state.authenticating = true;
            state.loginError = ""
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.authenticating = false;
            state.user = action.payload

        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.authenticating = false;
            state.code = 401
            clearJwtToken();
            state.user = null
            state.jwt = ""
            console.log("invalid login", action.error.message)
            state.loginError = i18n.t("invalid-login")

        });
        //Fecth device info
        builder.addCase(createMember.pending, (state) => {
            state.creating = true;
        });
        builder.addCase(createMember.fulfilled, (state, action) => {
            state.creating = false;
            state.jwt = action.payload.jwt;
            state.user = action.payload.user
            storeJwtToken(action.payload.jwt)
            setAuthorization(action.payload.jwt)

        });
        builder.addCase(createMember.rejected, (state, action) => {
            state.creating = false;

        });
    }
})

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const {
    setSignUpBirthday,
    setSignUpEmail,
    setSignUpFirstName,
    setSignUpLastName,
    setSignUpPhone,
    setSignUpGender,
    setUserData,
    clearLoginError
} = authSlice.actions

export {
    setSignUpBirthday,
    setSignUpEmail,
    setSignUpFirstName,
    setSignUpLastName,
    setSignUpPhone,
    setSignUpGender,
    setUserData,
    clearLoginError
}

export default authSlice