import i18n from 'i18n-js'
import React from 'react'
import { Color } from '../../../utils/Colors'
import OrderCard from './OrderCard'

const OrdersSideBar = ({ orders }) => {
  return (
    <div style={{
      width : 300,
      height : "90vh",
      overflowY: 'scroll',
      display: 'flex',
      flexDirection : 'column'
    }}>
      {orders.length == 0 && <h2 style={{
        marginTop : 20,
        alignSelf : 'center'
      }}>{i18n.t("no-active-orders")}</h2>}
      {orders.map((order, key) => <OrderCard key={key} order={order}/>)}
    </div>
  )
}

export default OrdersSideBar