

import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';
import OrderMarker from './OrderMarker';
import { api, driverApi } from '../../../utils/api';

const OrdersMap = ({orders}) => {

  const auth = useSelector((state) => state.auth)

  const mapDefaults = {
    center: {
      lat: 32.07803641071806,
      lng: 34.80428361129862
    },
    zoom: 13
  }


  return <div flex={1} display="flex" style={{
    width: "100%",
    height: "90vh"
  }} >

    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
      defaultCenter={mapDefaults.center}
      defaultZoom={mapDefaults.zoom}
    >
      {orders && orders.map((order, key) => {
        return <OrderMarker
          key={key}
          lat={order.driver?.currentlocation?.latitude}
          lng={order.driver?.currentlocation?.longitude}
          order={order} />
      })}
    </GoogleMapReact>

  </div>
}

export default OrdersMap